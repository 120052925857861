@font-face {
    font-family: 'manrope';
    src: url('/fonts/manrope-bold-webfont.woff2') format('woff2'),
    url('/fonts/manrope-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'manrope';
    src: url('/fonts/manrope-extrabold-webfont.woff2') format('woff2'),
    url('/fonts/manrope-extrabold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'manrope';
    src: url('/fonts/manrope-extralight-webfont.woff2') format('woff2'),
    url('/fonts/manrope-extralight-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'manrope';
    src: url('/fonts/manrope-light-webfont.woff2') format('woff2'),
    url('/fonts/manrope-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'manrope';
    src: url('/fonts/manrope-medium-webfont.woff2') format('woff2'),
    url('/fonts/manrope-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'manrope';
    src: url('/fonts/manrope-regular-webfont.woff2') format('woff2'),
    url('/fonts/manrope-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'manrope';
    src: url('/fonts/manrope-semibold-webfont.woff2') format('woff2'),
    url('/fonts/manrope-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
