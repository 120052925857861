@import 'variables.scss';

.table {
    border-collapse: collapse;
    border-bottom: 1px solid #d4d4d4;

    th {
        background-color: $flatColor;
        color: $flatText;
        text-align: left;
        vertical-align: top;

        a {
            color: $flatText;
        }
    }

    td, th {
        padding: 6px 8px;
    }

    td {
        border-left: 1px solid #d4d4d4;
        border-right: 1px solid #d4d4d4;
    }

    tr:nth-child(odd) {
        td {
            background-color: $highlight;
        }
    }

    .sortorder:after {
        content: '\25b2';
    }

    .sortorder.reverse:after {
        content: '\25bc';
    }
}
