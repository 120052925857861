@import "variables";
@import "fonts";

@mixin top-level-menu-item {
    font-size: 16px;
    font-weight: 400;

    strong {
        font-size: 30px;
        font-weight: 700;
    }
}

// container
.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    height: $headerHeight;
    z-index: 3;
    
    busy-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        width: 100px;
    }
    
    // hamburger button ------------------------------------------------------------------------------------------------
    .hamburger-btn {
        position: relative;
        margin-left: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
        transition: all .5s ease-in-out;
    }

    .burger {
        width: 28px;
        height: 4.24px;
        background-color: $toolbarText;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
        transition: all .5s ease-in-out;
    }

    .burger::before, .burger::after {
        content: '';
        position: absolute;
        width: 28px;
        height: 4.24px;
        background-color: $toolbarText;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
        transition: all .5s ease-in-out;
    }

    .burger::before {
        transform: translateY(-10px);
    }

    .burger::after {
        transform: translateY(10px);
    }

    // hamburger button animation
    .hamburger-btn.open .burger {
        background: transparent;
        box-shadow: none;
    }

    .hamburger-btn.open .burger::before {
        transform: rotate(45deg);
    }

    .hamburger-btn.open .burger::after {
        transform: rotate(-45deg);
    }

    // portals logo
    .logo {
        
        padding-right: 47px;
        width: 150px;
    }
    
    .title {
        @include top-level-menu-item;
        color: $toolbarText;
        padding-left: 15px;
    }
    
    ul.tabs {
        list-style: none;
        display: flex;
        align-items: stretch;
        margin-left: 15px;
        color: $toolbarText;
        height: 100%;
        
        li {
            display: flex;
            padding: 10px;
            margin: 23px 5px 0 0;
            
            a {
                color: $toolbarText;
                text-decoration: none;
            }
            
        }
        
        li.active {
            background-image: linear-gradient(rgb(0 0 0/10%) 0 0);
            border-radius: 10px 10px 0 0;
        }
    }
}

.side-menu {
    width: 350px;
    position: fixed;
    top: $headerHeight;
    margin-top: 3px;
    border-radius: 10px;
    margin-left: -500px;
    z-index: 3;
    transition: all .5s ease-in-out;

    &.open {
        margin-left: 3px;
    }

    nav {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .current-user {
        width: 320px;
        height: 41px;
        background-color: white;
        border-radius: 10px;
        margin: 16px;
        font-size: .75rem;
        
        display: flex;
        align-items: center;
        // current client logo
        img {
            width: 125px;
            object-fit: contain;
        }
        // current user email
        span {
            padding-right: 8px;
            margin-left: auto;
        }
    }
    
    li.menu-item {
        flex: 1;
        margin: 0;
    }
    
    ul, menu-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        margin: 0 0 8px 0;
        padding: 0;
        list-style: none;
        
        li {
            color: $toolbarText;
            font-family: manrope, sans-serif;
            width: 320px;
            height: 42px;
            padding: 10px;
            cursor: pointer;
            
            a {
                @include top-level-menu-item;
                color: $toolbarText;
                text-decoration: none;
                padding-left: 20px;
                
                &:visited {
                    text-decoration: none;
                }
            }
        }
    }

    .menu-group {
        border-radius: 10px;
    }
    
    .divider {
        background-color: $toolbarText;
        height: 4px;
        border-radius: 3px;
        margin: 16px;
        width: 320px;
    }

    .menu-group.sub-menu {
        position: absolute;
        flex-basis: 100%;
        left: 355px;
        display: none;
        margin-top: -32px;
        
        li {
            padding-left: 24px;
            
            a {
                strong {
                    font-size: 16px;
                }
            }
        }
        
        .open {
            display: block;
        }
        
        .close-btn {
            display: flex;
            justify-content: end;
            align-items: center;
            font-size: 12px;
            
            i {
                font-size: 16px;
                margin-right: 20px;
            }
        }
    }
    
    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    
    .menu-group.sub-menu.open {
        display: block;
        animation: fadeIn .5s;
    }
    
    // align chevron to the right
    .menu-title {
        display: flex;
        align-items: center;
        
        img {
            align-self: baseline;
        }
        
        a {
            align-self: baseline;
        }
        
        span {
            margin-left: auto;
            align-self: center;
        }
    }
}

.menu-backdrop {
    color: transparent;
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 2;
}

// This is just a static navbar with just the logo to show when there's no user logged in.
.navbar-fixed {
    background-color: $bgPortal;
    display: flex;
    align-items: center;
    justify-content: end;
    
    img {
        margin-left: auto;
    }
}
