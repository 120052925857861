﻿$font: Calibri, 'Trebuchet MS', sans-serif;
$fontSize: 15px;

// layout dimensions
$menuWidth: 240px;
$headerHeight: 74px;
$toolbarHeight: 42px;
$contentMargin: 20px;

// colors
$text: #000000;
$link: #428bca;
$hover: #056cb6;
$active: #46b8da;
$actionIcon: #056cb6;
$header: #333333;
$headerText: #ffffff;
$menu: #164672;
$menuLink: #ffffff;
$menuHover: #46b8da;
$menuActive: #ffffff;
$menuActiveBg: #46b8da;
$highlight: #f0f0f0;
$toolbar: #2a6496;
$toolbarText: #ffffff;
$tab: #2a6496;
$tabText: #eeeeee;
$tabActive: #ffffff;
$flatColor: #2a6496;
$flatText: #ffffff;
$contentBackground: #ffffff;

//  new color palette for Portals
$bgPortal: #2B5257;
$bgWarn: #603A2D;
$bgReporting: #51572B;

