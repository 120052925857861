@charset "UTF-8";
/* CSS Document */

// structural styles for Angular Material dialog
@use '@angular/cdk' as cdk;
@include cdk.overlay();

@import 'variables.scss';

*, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    margin: 0;
    color: rgba(64,64,64,1.00);
    //background-color: rgba(248,248,255,1.00);
    font-size: 16px;
    height: 100%;
    font-family: manrope, "Helvetica Neue", Helvetica, Colibri, Arial, sans-serif;
    padding-bottom: 50px;
}

.container {
    padding: 8px;
}

.center-text {
    margin: auto;
    text-align: center;
}

.modal .modal-body {
    max-height: 400px;
    overflow-y: auto;
}

.navbar-brand {
    padding: 0;
    margin-left: 15px !important;
    margin-right: 15px !important;
}

#portalHeader {
    background: url("../images/company_white_bg.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: rgba(255,255,255,0.9);
    margin-top: 50px;
}

.companyLogo {
    max-width: 120px;
    vertical-align: middle;
}

.dotsOverlay {
    background: url(../images/bg-dots.png) repeat;
    margin: 0 -15px;
    /*padding: 6px 0;*/
}

#airportSelector .btn {
    font-size: 24px;
    margin: 3px 5px 7px 2px;
}

#airportSelectorList .btn {
    font-size: 24px;
}

section #airportSelector {
    display: inline-block;
}

section #airportSelectorList {
    display: inline-block;
    padding-left: 20px;
}

#clientInfo {
    min-height: 115px;
}

.alert-danger, .alert-error {
    padding: 12px;
    color: #b94a48;
    background-color: #f2dede;
    border-color: #eed3d7;
}

#airportSelector .btn-info:active, #airportSelector .btn-info.active {
    color: #fff;
    background-color: #2ee264;
    border-color: #0f9337;
}

hot-response {
    .selected, .selected td {
        background-color: #000000 !important;
        color: #ffff00 !important;
        font-weight: bold;
    }
}

.spacer {
    margin: 1px 10px !important;
    padding: 0;
    height: 50px;
}

footer {
    height: 60px;
}

#footer {
    margin-top: 120px;
    background-color: rgba(245,245,245,.90);
}

.alignCenter {
    text-align: center;
}

#hotReport {
    margin-top: 6px;
    background-color: rgba(242,242,242,1.00);
}

.hotSize {
    font-size: 14px;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.busyIndicator {
	height: 42px;
	width: 42px;
	animation: rotate 1s infinite linear;
	border: 6px solid #fff;
	border-right-color: transparent;
	border-radius: 50%;
}

.currentUser {
    float: right;
    line-height: 20px;
    font-size: 17px;
    padding-top: 15px;
    padding-right: 30px;
    color: #ffffff;
}

.currentUser a {
    padding-left: 30px;
}

input[readonly] {
    background-color: white !important;
    color: black !important;
}

.ng-invalid:not(fieldset):not(form):not(.noerror) {
    box-shadow: 0 0 1.5px 1px red;
}

/*animate.css -http://daneden.me/animate*/
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
}

.ui-dialog {
    z-index: 2000 !important;
}

.dispatchSearch, .deicingPortalSearch {
    display: inline-flex;
    align-items: center;
	//position: relative;
	//top: 58px;
	//left: 300px;
	//z-index: 1000;

	.mdi-magnify {
        position: absolute;
		padding: 3px 0 0 5px;
		color: #aaaaaa;
		height: 28px;
		font-size: 26px;
		//line-height: 1;
		//display: inline-block;
		//vertical-align: middle;
		//margin-top: -8px;
		//position: relative;
	}

	input {
		//margin-left: -35px;
        padding: 8px 8px 8px 35px;
		//padding: 5px 5px 5px 35px;
		//border: 1px solid #cccccc;
        border: none;
		border-radius: 4px;
        
        :focus {
            outline: none;
        }
	}

	.button {
        position: relative;
		//display: block;
		//float: right;
		//vertical-align: middle;
		//width: 23px;
		//height: 34px;
		//padding: 5px;
		color: #aaaaaa;
		margin-left: -27px;
		//position: relative;
	}
}

.dispatch {
	.airports .mdi:not(.button), .details .airportCodeWrapper .mdi:not(.button), dispatch-airport-legend .mdi:not(.button) {
		font-size: 26px;
		line-height: 1;
		display: inline-block;
		vertical-align: middle;
		margin-top: -4px;
		height: 24px;
	}

	.airports {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 56%;
		overflow: auto;
		background-color: #f1f1f1;

		.contentMargins {
			margin: 10px 0 10px 10px;
		}

		.adhoc-airports {
			&:after {
				content: "";
				display: block;
				clear: both;
			}
		}

		.subgroup {
			margin: 20px 0 -10px 0;

			h4 {
				margin: 0 10px 10px 10px;
			}

			&:after {
				content: "";
				display: block;
				clear: both;
			}
		}

		.airport {
			width: 200px;
			height: 80px;
			margin: 10px;
			box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.25);
			border-radius: 8px;
			float: left;
			background-color: #ffffff;
			cursor: pointer;

			&.selected {
				background-color: #eef3f7;
				box-shadow: 0px 0px 10px 1px rgba(0,0,64,0.25);

				.airportCodeWrapper {
					background-color: #2a6496;
					color: #ffffff;
				}
			}
			/* Airports with nowcast capability */
			/* TODO: nowcast, to be decided on the looks here */
			&.nowcast {
				background-color: #edf2ff;
			}

			.remove {
				display: none;
				margin: -10px 0 0 1px;
				color: #ff9999;
			}

			&.adhoc:hover {
				.remove {
					display: inline-block;

					&:hover {
						color: #ff6666;
					}
				}
			}

			.airportCodeWrapper {
				border-top-left-radius: 8px;
				border-bottom-right-radius: 6px;
				float: left;
				width: 50%;
				height: 40px;
				padding: 4px;
				background-color: #e0e0e0;

				.airportIcon {
					color: #aaaaaa;
					font-size: 28px;
					margin-top: -4px;
					height: 26px;
				}

				.plus {
					color: #aaaaff;
					position: absolute;
					margin-left: -14px;
					font-size: 22px;
					margin-top: 11px;
					font-weight: bold;
				}

				.airportCode {
					font-weight: bold;
					line-height: 32px;
				}

				.hotConditions {
					display: block;
					width: 190px;
					margin: -50px 0 0 0;

					.hotConditionIcon {
						float: right;
					}

					.activeFrost, .barrelIcing {
						color: #404040 !important;
					}
				}
			}

			.weather {
				float: right;
				width: 50%;
				height: 40px;
				padding: 4px;
				overflow: hidden;
				line-height: 32px;

				.weatherIcon {
					display: inline-block;
					margin: -5px -4px -13px -4px;
					width: 40px;
					height: 40px;
					background-size: cover;

					&.withPrecip {
						margin: -8px -4px -10px -4px;
					}
				}
			}

			.hots {
				clear: both;
				display: flex;
				padding: 5px 2px;

                .table {
                    width: 100%;
                }
                
				.hot {
					width: 42px;
					margin: 0 0 0 5px;
					padding: 3px;
					border-radius: 4px;
					text-align: center;
					font-weight: bold;

					&.type1 {
						background-color: #ffcc66;
					}

					&.type2 {
						background-color: #f6f6f6;
					}

					&.type3 {
						background-color: #ffff33;
					}

					&.type4 {
						background-color: #99ff66;
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}

		.alerts {
			.alert {
				height: 38px;
				margin: 20px 10px 10px 10px;
				box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.25);
				border-radius: 8px;
				background-color: #ffffff;
				cursor: pointer;
				padding: 0;
				border-style: none;

				&.selected {
					background-color: #eef3f7;
					box-shadow: 0px 0px 10px 1px rgba(0,0,64,0.25);

					.airportCodeWrapper {
						background-color: #2a6496;
						color: #ffffff;
					}
				}

				.airportCodeWrapper {
					border-top-left-radius: 8px;
					border-bottom-left-radius: 8px;
					float: left;
					height: 38px;
					padding: 4px;
					background-color: #e0e0e0;
					width: 100px;

					.hotConditionIcon {
						color: #aaaaaa;
						font-size: 28px;
						margin-top: -7px;
						height: 26px;
					}

					.airportCode {
						font-weight: bold;
						line-height: 32px;
					}
				}

				.message {
					padding: 9px;
					margin-right: 40px;
					display: block;
					overflow: hidden;
					height: 36px;
				}

				.mdi-close {
					float: right;
					padding: 10px 5px;
					margin-top: -40px;
				}
			}
		}
	}

	.border {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 56%;
		overflow: hidden;
		width: 8px;
		overflow: auto;
		border-left: 8px solid #e5e5e5;
		cursor: w-resize;
		z-index: 1;
	}

	.hotConditions {
		.code {
			display: block;
			width: 24px;
			position: relative;
			margin: -23px 0 0 1px;
			text-align: center;
			font-size: 14px;
			color: #ffffff;
			font-weight: bold;
		}
	}

	.details {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		overflow: hidden;
		left: calc(56% + 4px);
		overflow: auto;

		table {
			margin-bottom: 25px;
		}

		.airportCodeWrapper {
			padding: 15px 20px 15px 15px;
			height: 70px;
			background-color: #eef3f7;
			overflow: hidden;

			.hotConditionIcon {
				color: #aaaaaa;
				font-size: 36px;
				padding-right: 5px;
				height: 44px;
			}

			/* For the radar icon */
			.nowcastIcon {
				color: #aaaaaa;
				font-size: 36px;
				padding-right: 5px;
				height: 44px;
			}

			.plus {
				color: #aaaaff;
				position: absolute;
				margin-left: -14px;
				font-size: 22px;
				margin-top: 20px;
				font-weight: bold;
			}

			.airportCode {
				font-weight: bold;
				font-size: 28px;
				background-color: #2a6496;
				color: #ffffff;
				border-radius: 6px;
				padding: 6px 8px;
				margin: 0 2px 0 8px;
			}

			.weatherIcon {
				display: inline-block;
				margin: -5px 0 -8px 5px;
				width: 40px;
				height: 40px;
				background-size: cover;

				&.withPrecip {
					margin: -5px 0 -10px 5px;
				}
			}

			.hotConditions {
				display: inline-block;
				margin-top: -3px;
			}

			.forecastMessage {
				margin-top: 10px;
				display: inline-block;
				vertical-align: top;
			}

			.requestTime {
				display: inline-block;
				float: right;
				padding-top: 8px;
			}

			.refresh {
				float: right;
				padding: 5px;
				margin-left: 10px;
			}
		}

		.editWeatherType, .editTemperature, .showWeatherTypePopup, .showIntensityPopup {
			float: right;
			padding: 5px;
		}

		.weatherTypeSelection, .intensitySelection {
			margin-bottom: 10px;

			.caption {
				width: 175px;
				display: inline-block;
				line-height: 36px;
				vertical-align: middle;

				.mdi {
					margin-left: 3px;
					margin-right: 5px;
				}
			}

			.weatherType, .intensity {
				display: inline-block;
				line-height: 36px;
				vertical-align: middle;
			}
		}

		.text-center {
			text-align: center;
		}

		.row {
			display: flex;
			padding: 0;
			margin: 8px 0;
			width: 100%;
		}

		.col {
			-webkit-box-flex: 1;
			-webkit-flex: 1;
			-moz-box-flex: 1;
			-moz-flex: 1;
			-ms-flex: 1;
			flex: 1;
			display: block;
			padding: 5px;
			width: 100%;
		}
        
        .col .col-60 {
            flex: 0 0 60%;
        }
        
        .col .col-40 {
            flex: 0 0 40%;
        }

		.fluidList {
			position: relative;
			overflow: hidden;

			.fluid {
				border: 1px solid gray;
				padding: 8px;

				.lineSample {
					display: inline-block;
					vertical-align: top;
					width: 60px;
					height: 15px;
					background-position: bottom;
					background-repeat: repeat-x;
					background-size: 16px 4px;

					&.type1 {
						background-image: linear-gradient(to right, #ffcc66 100%, #ffffff 0%);

						&.composite {
							background-image: linear-gradient(to right, #ccaa77 100%, #ffffff 0%);
						}
					}

					&.type2 {
						background-image: linear-gradient(to right, #dddddd 100%, #ffffff 0%);

						&.dilution75 {
							background-image: linear-gradient(to right, #dddddd 75%, #ffffff 0%);
						}

						&.dilution50 {
							background-image: linear-gradient(to right, #dddddd 50%, #ffffff 0%);
						}

						&.generic {
							background-image: linear-gradient(to right, #cccccc 100%, #ffffff 0%);

							&.dilution75 {
								background-image: linear-gradient(to right, #cccccc 75%, #ffffff 0%);
							}

							&.dilution50 {
								background-image: linear-gradient(to right, #cccccc 50%, #ffffff 0%);
							}
						}
					}

					&.type3 {
						background-image: linear-gradient(to right, #ffff33 100%, #ffffff 0%);

						&.dilution75 {
							background-image: linear-gradient(to right, #ffff33 75%, #ffffff 0%);
						}

						&.dilution50 {
							background-image: linear-gradient(to right, #ffff33 50%, #ffffff 0%);
						}

						&.generic {
							background-image: linear-gradient(to right, #cccc33 100%, #ffffff 0%);

							&.dilution75 {
								background-image: linear-gradient(to right, #cccc33 75%, #ffffff 0%);
							}

							&.dilution50 {
								background-image: linear-gradient(to right, #cccc33 50%, #ffffff 0%);
							}
						}
					}

					&.type4 {
						background-image: linear-gradient(to right, #99ff66 100%, #ffffff 0%);

						&.dilution75 {
							background-image: linear-gradient(to right, #99ff66 75%, #ffffff 0%);
						}

						&.dilution50 {
							background-image: linear-gradient(to right, #99ff66 50%, #ffffff 0%);
						}

						&.generic {
							background-image: linear-gradient(to right, #99cc66 100%, #ffffff 0%);

							&.dilution75 {
								background-image: linear-gradient(to right, #99cc66 75%, #ffffff 0%);
							}

							&.dilution50 {
								background-image: linear-gradient(to right, #99cc66 50%, #ffffff 0%);
							}
						}
					}
				}
			}
		}

		.hot {
			min-height: 80px;
            display: flex;
            flex-direction: column;
            justify-content: end;
		}

		.timer {
			display: flex;

			.startLabel, .upperLabel, .lowerLabel, .currentLabel {
				flex: 0 0 auto;
				font-size: 22px;

				.caption {
					font-size: 12px;
				}
			}

			.startLabel, .upperLabel, .lowerLabel {
				text-align: center;
			}

			.upperLabel {
				margin-left: 60px;
			}

			.currentLabel {
				text-align: left;
				height: 41px;
			}

			> .middle {
				flex: 1 0 auto;

				.progressBar {
					height: 20px;
					vertical-align: middle;
					position: relative;
					z-index: -1;

					.upperProgressBar, .lowerProgressBar {
						height: 20px;
						border-radius: 10px;
						border-width: 1px;
						border-style: solid;
						border-color: #cccccc;
						position: relative;
					}

					.lowerProgressBar {
						margin-top: -20px;
					}

					.upperProgressBar.thin, .lowerProgressBar.thin {
						height: 12px;
					}

					.lowerProgressBar.thin {
						margin-top: -12px;
					}
				}

				.times {
					display: flex;

					.middle {
						flex: 1 0 auto;

						h2 {
							margin-top: 10px;
							font-size: 20px;
							color: #387ef5;
						}
					}
				}
			}

			.timer .middle {
				padding: 0 15px 0px 15px;
			}

			.start {
				flex: 0 0 auto;
				cursor: pointer;

				&.circle {
					margin-top: 18px;
					color: #387ef5;
					border-color: #387ef5;
				}
			}

			.end {
				flex: 0 0 auto;
				cursor: pointer;

				&.circle {
					margin-top: 18px;
					color: #cc3333;
					border-color: #cc3333;
				}
			}
		}

		.lowerProgressBar.min {
			background-color: #ffcc99;
		}

		.lowerProgressBar.max {
			background-color: #ccffcc;
		}

		.holdoverTimeMessage {
			padding: 5px 0;
		}

		.backgroundColour {
			background-color: #ffffff;
		}

		.hotOptions {
			align-items: flex-start;
			margin: -8px 0 -5px 0;
			padding-top: 15px;

			.addOverrideFluid {
				margin: 0;
				padding: 0;

				button {
					font-size: 25px;
				}
			}

			.holdoverTaxiTimePrediction {
				margin-left: 10px;
				-webkit-box-flex: 0;
				-webkit-flex: 0 0 80%;
				-moz-box-flex: 0;
				-moz-flex: 0 0 80%;
				-ms-flex: 0 0 80%;
				flex: 0 0 80%;
				max-width: 80%;

				.holdoverTaxiTimeMessage {
					text-align: right;
				}

				.holdoverTaxiTimeLines {
					.holdoverTaxiTimeLower, .holdoverTaxiTimeUpper {
						height: 100%;
						border-right: 3px solid black;
						position: absolute;
						top: 33px;
						z-index: -1;
					}
				}

				.holdoverTaxiTimeLabels {
					.holdoverTaxiTimeLower, .holdoverTaxiTimeUpper {
						position: absolute;
						margin-top: -40px;
						width: 50px;
						padding-left: 4px;
					}
				}
			}
		}
	}
}

.weatherTypePopup, .intensityPopup {
    .item {
        background-color: #fff;
        color: #444;
        position: relative;
        z-index: 2;
        display: block;
        margin: -1px;

        .item-content {
            position: relative;
            z-index: 2;
            padding: 8px 48px 8px 8px;
            border: none;
            background-color: #fff;

            .mdi {
                position: absolute;
                top: 1px;
                right: 8px;
            }
        }
    }
}

.ui-widget {
    font-family: $font;
    font-size: $fontSize;
}

.toolbar {
    background-color: $toolbar;
    color: #ffffff;
    padding-left: $contentMargin;
    padding-right: $contentMargin;
    box-shadow: 0 5px 4px -2px rgba(158, 158, 158, 0.3);
    line-height: $toolbarHeight;

    &:after {
        content: "";
        display: block;
        clear: both;
    }

    > * {
        vertical-align: middle;
    }

    > .tab:first-child {
        margin-left: -14px;
    }

    .tab {
        display: inline-block;
        padding: 0 15px 0 15px;
        background-color: $tab;
        color: $tabText;
        margin-right: 16px;
        outline: none;
        text-transform: uppercase;
        cursor: pointer;
        border-bottom: 3px solid transparent;

        &:hover {
            color: $tabActive;
            cursor: pointer;
            text-decoration: none;
        }

        &.active {
            color: $tabActive;
            border-bottom: 3px solid $tabActive;
        }

        &.invalid {
            border-bottom: 3px solid red;
        }
    }

    label, .field {
        min-height: $toolbarHeight;
        line-height: $toolbarHeight - 4;
    }

    h3 {
        display: inline-block;
        margin: 0 16px 0 0;
        padding: 0;
        min-height: $toolbarHeight;
        line-height: $toolbarHeight - 4;
    }
}

.navbar-static-top {
    border-bottom: 0;
}

.contentWrapper {
    position: absolute;
    top: $headerHeight;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    background-color: #ffffff;
    z-index: 1;

    .contentMargins {
        margin: 20px;
    }
}

.table {
    a.mdi {
        cursor: pointer;
    }
}

sortable-item {
    background-color: #ffffff;
}

.airportGroups {
	.groups {
		position: absolute;
		top: $toolbarHeight;
		bottom: 0;
		left: 0;
		width: 300px;
		overflow-y: scroll;
		border-right: 2px solid #f1f1f1;
		padding: 10px;

		input[type=button] {
			text-align: center;
			margin: 10px 0 10px 3px;
		}

		.group {
			background-color: #f1f1f1;
		}

		.subgroup {
			&.selected {
				background-color: #cccccc;
			}
		}

		.dragIcon {
			margin-right: 0;
		}

		.groupName {
			margin-right: 6px;
			float: right;

			input[type="text"] {
				width: 140px;
			}
		}

		.groupActions {
			float: right;
		}
	}

	.airports {
		position: absolute;
		top: $toolbarHeight;
		bottom: 0;
		right: 0;
		overflow: hidden;
		left: 300px;
		overflow: auto;
		padding: 10px;

		.airport {
			width: 200px;
			height: 80px;
			margin: 10px;
			border: 1px solid #999999;
			border-radius: 8px;
			float: left;
			background-color: #ffffff;
			cursor: pointer;
			padding: 2px 4px;
			line-height: 20px;

			&.selected {
				background-color: #cccccc;

				.airportCodeWrapper {
					background-color: #c0c0c0;
				}
			}

			.airportCodeWrapper {
				border-top-left-radius: 8px;
				border-bottom-right-radius: 6px;
				float: left;
				width: 50%;
				height: 40px;
				padding: 4px;
				background-color: #e0e0e0;
				margin: -2px 6px 0 -4px;

				.mdi-map-marker {
					color: #aaaaaa;
					font-size: 28px;
					margin-top: -4px;
					height: 26px;
				}
			}

			overflow: hidden;
		}
	}
}

.items {
    .item {
        display: block;
        margin: 3px;
        border: 1px solid #999999;
        border-radius: 3px;

        &.placeholder {
            height: 38px;
            margin-bottom: 15px;
        }

        > * {
            vertical-align: middle !important;
            margin: 3px 4px;
        }

        input[type=text] {
            width: 135px;
        }

        .mdi {
            font-size: 20px;
        }
    }

    &:after {
        content: "";
        display: block;
        clear: both;
    }

    .items {
        margin: 5px 0 10px 25px;
    }
}

select-menu.icons {
    font-family: 'Material Design Icons';
    font-size: 24px;
    line-height: 24px;
}

select-menu {
    display: inline-block;
    color: #000000;

    > * {
        display: inline-block;
        vertical-align: middle;
    }

    .display {
        width: 272px;
        height: 28px;
        line-height: 20px;
        padding: 2px 24px 2px 2px;
        background-color: #ffffff;
        border: 1px solid #666666;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        border-radius: 3px;

        &.placeholder {
            color: #999999;
        }
    }

    .button {
        width: 22px;
        height: 28px;
        padding: 5px;
        color: #333333;
        margin-left: -27px;
    }

    .overlay {
        position: fixed;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .options {
        display: block;
        position: absolute;
        z-index: 3;
        background-color: #ffffff;
        color: #000000;
        border-right: 1px solid #666666;
        border-bottom: 1px solid #666666;
        border-left: 1px solid #666666;
        width: 273px;
        max-height: 400px;
        overflow-x: auto;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;

        select-menu-option {
            label {
                display: block !important;
                line-height: 24px;
                margin-right: 0 !important;
                min-height: unset;
                padding: 4px;

                &.selected {
                    background-color: #4444ff;
                    color: #ffffff;
                }

                &::after {
                    content: '';
                    white-space: initial;
                    line-height: 0;
                }
            }
        }
    }
}

.analyticsOptions {
	position: absolute;
	top: 25px;
    right: 250px;
	z-index: 1000;

	input[type="button"] {
        cursor: pointer;
        padding: 6px 12px;
        text-transform: uppercase;
        border: none;
        border-radius: 5px;
		margin: 0 5px;
	}
}

.analytics {
    .viewWrapper {
        iframe {
            margin: 0 auto;
        }
    }
}

.lweGrid {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;

	ag-grid-angular.ag-theme-fresh {
		.ag-header {
			.ag-header-cell {
				flex-direction: column;
			}

			.ag-icon {
				line-height: 12px;
			}

			.ag-header-row:nth-child(1) {
				.ag-header-cell {
					.ag-header-cell-text {
						font-weight: normal;
					}
				}
			}
		}

		.ag-paging-panel .notice {
			flex: 1 1 auto;
			font-weight: bold;
			font-size: 20px;
			margin-left: 0;
			color: #dd0000;
		}
	}
}

.deicingPortal {
	display: flex;
	flex-direction: column;

	.mdi:not(.button) {
		font-size: 26px;
		line-height: 1;
		display: inline-block;
		vertical-align: middle;
		margin-top: -4px;
		height: 24px;
	}

	.airports {
		flex: 0 1 auto;
		overflow-y: auto;
		padding: 8px 5px 8px 5px;
		min-height: 74px;

		.airport {
			width: 105px;
			height: 48px;
			margin: 5px;
			padding: 4px;
			border-radius: 8px;
			float: left;
			background-color: #ffffff;
			cursor: pointer;

			&.selected {
				background-color: #eef3f7;
				box-shadow: 0px 0px 10px 1px rgba(0,0,64,0.25);

				.airportCodeWrapper {
					background-color: #2a6496;
					color: #ffffff;
				}
			}

			.airportCodeWrapper {
				border-radius: 8px;
				height: 40px;
				padding: 4px;
				background-color: #e0e0e0;
				text-align: center;

				.airportCode {
					font-weight: bold;
					font-size: 28px;
					line-height: 32px;
				}
			}
		}
	}

	.content {
		flex: 1 1 auto;
		position: relative;
		overflow: hidden;

		.topPane {
			display: flex;
			flex-direction: column;
			height: 65%;
		}

		.bottomPane {
			position: absolute;
			top: calc(65% + 8px);
			bottom: 0;
			left: 0;
			right: 0;
			overflow: hidden;
			transition: padding-left .3s;
		}

		.horizontalBorder {
			position: absolute;
			top: 65%;
			right: 0;
			left: 0;
			overflow: hidden;
			height: 8px;
			overflow: auto;
			border-bottom: 8px solid #e5e5e5;
			cursor: s-resize;
			z-index: 1;
			transition: left .3s;
		}
	}

	.details {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		.selectedAirport {
			flex: 0 1 auto;

			.airportCodeWrapper {
				padding: 15px 20px 15px 15px;
				height: 70px;
				background-color: #eef3f7;
				overflow: hidden;

				.airportCode {
					font-weight: bold;
					font-size: 28px;
					background-color: #2a6496;
					color: #ffffff;
					border-radius: 6px;
					padding: 6px 8px;
					margin: 0 2px 0 8px;
				}

				.requestTime {
					display: block;
					float: right;
					padding-top: 8px;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
				}

				.requestTimeInput {
					display: inline-block;
					float: right;
					width: calc(100% - 250px);
					margin-top: -8px;

					[date] {
						width: 120px;
						margin-left: 5px;
					}

					.time {
						margin-top: 3px;

						input {
							width: calc(100% - 100px);
							display: inline-block;
						}
					}
				}

				.refresh, .play, .pause {
					float: right;
					padding: 5px;
					margin-left: 10px;
				}
			}
		}

		.flightCategories {
			display: flex;
			flex-direction: row;
			flex: 1 1 auto;
			background-color: #eef3f7;
			padding: 0 5px;
			overflow: hidden;

			.flightCategory {
				width: 320px;
				flex: 1 1 auto;
				padding: 0;
				display: flex;
				flex-direction: column;
				margin: 0 5px;
				background-color: #ffffff;

				.title {
					flex: 0 1 auto;
					text-align: center;
					font-size: 20px;
					padding: 5px 0;
				}

				.summary {
					flex: 0 1 auto;
					text-align: center;
					font-size: 18px;
					margin: 10px;
				}

				.flights {
					flex: 1 1 auto;
					overflow: auto;

					.flightsWrapper {
						.flight {
							margin: 3px 5px;
							border-radius: 5px;
							background-color: #ffffff;
							border: 2px solid #e2e2e2;
							display: flex;
							flex-direction: row;
							padding: 2px;

							.left {
								flex: 0 0 auto;
								margin: 2px 0 0 0;

								.mdi-airplane {
									text-align: center;
									width: 24px;
									font-size: 24px;
									margin-top: -6px;
								}

								.laneTime {
									padding: 1px;
									border-radius: 4px;
									background-color: #e2e2e2;

									&.overtime {
										background-color: #ffdfdf;
										color: #a00;
									}
								}
							}

							.right {
								flex: 1 1 auto;
								line-height: 19px;
								margin: 3px 4px 0 0;

								span {
									margin: 0 0 0 7px;
									display: inline-block;
								}
							}
						}
					}
				}
			}
		}
	}
}

.engineCoverAlerts {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;

	.toolbar {
		flex: 0 1 auto;
	}

	.RedEntry {
		background-color: #ff0000;
	}

	.OrangeEntry, .ForcedOrangeEntry {
		background-color: #ff8c00;
	}
}

.mobileAppReleases {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

// region Login


#login-view.login-container {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;

    .login {
        margin-top: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 664px;
        height: 353px;

        background-color: $bgPortal;
        border-radius: 30px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

        .logo {
            width: 140px;
            margin-top: auto;
            margin-bottom: 7px;
        }

        .form-group {
            width: 480px;
        }

        label {
            font-weight: 700;
            color: $toolbarText;
            font-size: small;
            display: block;
            padding: 3px 0;
        }
        
        #email {
            margin-bottom: 23px;
        }
        
        #password {
            margin-bottom: 8px;
        }
        
        #forgotten-password, #register {
            font-family: manrope, sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            line-height: 18px;
        }

        input {
            height: 41px;
            padding: 10px;
            border: none;
            width: 100%;
            border-radius: 10px;
        }

        a {
            color: $toolbarText;
            text-decoration: none;
            font-size: x-small;
            font-weight: lighter;
        }

        input[type=submit] {
            width: 139px;
            height: 41px;
            color: $bgPortal;
            font-weight: 400;
            font-size: 16px;
            border-radius: 10px;
            text-transform: uppercase;
            padding: 10px 47px 9px 43px;
            margin-top: 10px;
        }
    }

    .login-backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url("/images/login_bg.png");
        z-index: -1;
    }
    
    .privacy-policy {
        color: white;
        font-style: normal;
        font-size: 10px;
        font-weight: 400;
        align-self: start;
        margin-top: auto;
        margin-left: 27px;
        margin-bottom: 10px;
    }
}

// endregion

.errorPopup {
    .ui-dialog-titlebar {
        background: #d66;
        color: #fff;
    }
}



.title {
    margin: 30px 0;
    font-size: 1.5rem;
}

/* airport search autocomplete */
ul.ui-autocomplete {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    width: auto;
}
